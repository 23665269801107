import React from 'react'
import styled from '@emotion/styled'

import darumaImg from '@/assets/images/about/daruma.webp'
import { media } from '@/styles/media'
import { useTranslation } from 'react-i18next'
import SubTitle from '../home/Subtitle'

const Wrapper = styled.div`
  background: #001b2e;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const DarumaWrapper = styled.div`
  height: 100vh;
  position: sticky;
  display: flex;
  width: 100%;

  top: 0;
  z-index: 1;
  mask-image: linear-gradient(
    to bottom,
    transparent,
    black 7%,
    black 80%,
    transparent
  );

  ${media.desktopDown} {
    height: 65vh;
  }
`

const TitleContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;

  ${media.desktopDown} {
    gap: 9px;
  }
`

const DarumaImageWrapper = styled.div`
  z-index: 1;
  position: sticky;
  top: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;
  transform: translateY(20%);

  ${media.desktopDown} {
    height: 80vh;
  }
`

const TitleWrapper = styled.div`
  position: absolute;
  top: 0;
  gap: 72px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: calc(100vw - 192px - 400px);

  ${media.newLargeDesktopDown} {
    max-width: calc(100vw - 192px);
  }
  ${media.bigDesktopDown} {
    max-width: calc(100vw - 48px);
    height: auto;
  }

  ${media.bigDesktopDown} {
    justify-content: start;
    gap: 140px;
    padding: 48px 0;
  }
`

const Kanji = styled.span`
  z-index: 1;
  color: #002e4e;
  font-family: 'Zen Antique Soft';
  font-size: 485.5px;
  font-style: normal;
  font-weight: 700;
  line-height: 485.5px;
  text-transform: uppercase;
  transform: translateY(-20%);
  white-space: nowrap;

  ${media.desktopDown} {
    font-size: 120px;
    line-height: 120px;
    writing-mode: vertical-lr;
    transform: translateY(-10%);
  }
`

const Title = styled.h2`
  margin: 0;
  z-index: 99;
  color: #f8f5ee;
  text-align: center;
  font-family: Inter;
  font-size: 180px;
  font-style: normal;
  font-weight: 500;
  line-height: 180px;
  letter-spacing: -10.8px;

  i {
    font-family: 'Argent CF';
    color: #e2421f;
  }

  ${media.desktopDown} {
    font-size: 55.808px;
    font-weight: 500;
    line-height: 55.808px;
    letter-spacing: -3.907px;
  }
`

const Description = styled.h4`
  margin: 0;
  color: #ece4ce;
  z-index: 99;
  text-align: center;
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: 72px;
  letter-spacing: -0.96px;

  ${media.desktopDown} {
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.44px;
    padding: 0 24px;
  }
`

const Daruma = styled.img`
  width: auto;
  z-index: 2;
  height: 70vh;
  object-fit: cover;

  ${media.desktopDown} {
    width: 155px;
    height: auto;
  }
`

export default function Hero() {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <DarumaWrapper>
        <DarumaImageWrapper>
          <Daruma src={darumaImg} />
          <Kanji translate="no">日本体験</Kanji>
        </DarumaImageWrapper>
      </DarumaWrapper>
      <TitleWrapper>
        <TitleContent>
          <SubTitle color="#F8F5EE" center translate="no">
            <span>COMPANY PROFILE</span>
            <span>会社概要</span>
          </SubTitle>
          <Title>
            We are <i>ICHIGO</i>
          </Title>
        </TitleContent>

        <Description>{t('about_us.hero_description')}</Description>
      </TitleWrapper>
    </Wrapper>
  )
}
