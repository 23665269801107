/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
import { media } from '@/styles/media'
import styled from '@emotion/styled'
import { EmblaOptionsType } from 'embla-carousel'
import Autoplay from 'embla-carousel-autoplay'
import useEmblaCarousel from 'embla-carousel-react'
import React, {
  Children,
  cloneElement,
  isValidElement,
  ReactNode,
  useCallback,
  useEffect,
  useState
} from 'react'

const Wrapper = styled.div<{ widthMobile?: number; widthDesktop?: number }>`
  position: relative;

  .embla {
    position: relative;
    background-color: transparent;
    padding: 0;
    margin: 0;
    width: 100vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .embla__viewport {
    overflow: hidden;
    width: 100%;
  }

  .embla__container {
    display: flex;
    align-items: center;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -webkit-tap-highlight-color: transparent;
  }

  .embla__slide {
    flex: ${({ widthMobile }) => `0 0 ${widthMobile}%`};
    margin-right: 2rem;

    ${media.tabletLandscape} {
      flex: ${({ widthDesktop }) => `0 0 ${widthDesktop}%`};
    }
  }

  .embla__button {
    outline: 0;
    cursor: pointer;
    background-color: transparent;
    touch-action: manipulation;
    z-index: 1;
    border: 0;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    fill: #0cf;
    padding: 0;
  }

  .embla__button:disabled {
    cursor: default;
    opacity: 0.3;
  }

  .embla__button__svg {
    width: 100%;
    height: 100%;
  }

  .embla__button--prev {
    position: absolute;
    //top: translateY(50%); translateY can't be used for top
    left: 5px;
  }

  .embla__button--next {
    position: absolute;
    //top: translateY(50%); translateY can't be used for top
    right: 5px;
  }

  .embla__button__bottom--prev {
    position: absolute;
    left: 30%;
    bottom: -50px;

    ${media.tabletLandscape} {
      left: 42.5%;
    }

    ${media.hiDpi} {
      bottom: 30px;
    }
  }

  .embla__button__bottom--next {
    position: absolute;
    right: 30%;
    bottom: -50px;

    ${media.tabletLandscape} {
      right: 42.5%;
    }

    ${media.hiDpi} {
      bottom: 30px;
    }
  }
`

const SmallIndex = styled.div`
  display: none;
  background: transparent;
  height: 100%;

  ${media.tabletLandscape} {
    display: flex;
    align-items: center;
    position: absolute;
    // Font size should be set on parent element.
    font-size: inherit;
    right: 3%;
    font-family: 'Argesta Headline', sans-serif;
  }
`

export function EmblaCarousel({
  children,
  options = {},
  className,
  widthDesktop = 20,
  widthMobile = 60,
  autoplay = true,
  autoPlayDuration = 3000,
  displayIndex = false,
  initIndex = 0,
  onChangeIndex
}: {
  children: ReactNode
  options?: EmblaOptionsType
  className?: string
  widthDesktop?: number
  widthMobile?: number
  autoplay?: boolean
  autoPlayDuration?: number
  displayIndex?: boolean
  onChangeIndex?: (index: number) => void
  initIndex?: number
}) {
  const [emblaRef, embla] = useEmblaCarousel(
    { ...options },
    autoplay
      ? [
          Autoplay({
            delay: autoPlayDuration,
            stopOnInteraction: false,
            stopOnMouseEnter: true
          })
        ]
      : []
  )
  const [selectedIndex, setSelectedIndex] = useState(0)

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])

  const onSelect = useCallback(() => {
    if (!embla) return
    setSelectedIndex(embla.selectedScrollSnap())
  }, [embla, setSelectedIndex])

  const handleScrollTo = useCallback(
    (index: number) => {
      if (!onChangeIndex) return
      embla?.scrollTo(index)
      onChangeIndex(index)
    },
    // eslint-disable-next-line
    [embla]
  )

  function handleKeydown(e: KeyboardEvent) {
    if (e.key === 'ArrowRight') scrollNext()
    if (e.key === 'ArrowLeft') scrollPrev()
  }

  useEffect(() => {
    if (!embla) return
    onSelect()
    embla.on('select', onSelect)
    embla.on('init', () => {
      setTimeout(() => {
        embla?.scrollTo(initIndex)
      }, 100)
    })
    document.addEventListener('keydown', handleKeydown)
    return () => document.removeEventListener('keydown', handleKeydown)
  }, [embla])

  useEffect(() => {
    setTimeout(() => {
      embla?.scrollTo(initIndex)
      setSelectedIndex(initIndex)
    }, 100)
  }, [initIndex])

  useEffect(() => {
    if (onChangeIndex) {
      onChangeIndex(selectedIndex)
    }
  }, [selectedIndex, onChangeIndex])

  useEffect(() => {
    scrollPrev()
  }, [scrollPrev])
  useEffect(() => {
    scrollNext()
  }, [scrollNext])

  return (
    <Wrapper
      widthMobile={widthMobile}
      widthDesktop={widthDesktop}
      dir={options.direction}
      className={className}
    >
      <div className="embla">
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container">
            {Children.map(children, (child, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="embla__slide" key={index}>
                <div
                  className="embla__slide__inner"
                  onClick={() => handleScrollTo(index)}
                >
                  {isValidElement(child) ? cloneElement(child) : child}
                </div>
              </div>
            ))}
          </div>
        </div>
        {displayIndex && (
          <SmallIndex>
            {selectedIndex + 1}/{Children.count(children)}
          </SmallIndex>
        )}
      </div>
    </Wrapper>
  )
}

EmblaCarousel.defaultProps = {
  options: {},
  className: '',
  widthDesktop: 20,
  widthMobile: 60,
  autoplay: true,
  autoPlayDuration: 3000,
  displayIndex: false,
  onChangeIndex: null,
  initIndex: 0
}
